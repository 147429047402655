.nav-bar {
    background: #BDCDD6;
    width: 65px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;
  
    .logo {
      display: block;
      padding: 8px 0;
  
      img {
        display: block;
        margin: 8px auto;
        width: 50px;
        height: auto;
      }
    }
  
    nav {
      display: block;
      text-align: center;
      position: absolute;
      height: 210px;
      top: 50%;
      margin-top: -120px;
      width: 100%;
  
      a {
        font-size: 24px;
        color: #146C94;
        display: block;
        line-height: 51px;
        height: 51px;
        position: relative;
        text-decoration: none;
  
        i {
          transition: all 0.3s ease-out;
        }
  
        &:hover {
          svg {
            opacity: 0;
          }
  
          &:after {
            opacity: 1;
          }
        }
  
        &:after {
          content: '';
          font-size: 9px;
          letter-spacing: 2px;
          position: absolute;
          bottom: 0;
          display: block;
          width: 100%;
          text-align: center;
          opacity: 0;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }
  
        &:first-child {
          &:after {
            content: 'HOME';
          }
        }
      }
  
      a.about-link {
        &:after {
          content: 'ABOUT';
        }
      }

      a.contact-link {
        &:after {
          content: 'CONTACT';
        }
      }

      a.portfolio-link {
        &:after {
          content: 'MY WORK';
        }
      }

      a.active {
        svg {
            color: #19A7CE;
        }
      }
    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;

        li {
            a {
                padding: 7px 0;
                display: block;
                font-size: 18px;
                line-height: 16px;

                &:hover svg {
                    color: #19A7CE;
                }
            }
        }
    }
}

.hamburger-icon, .close-icon {
  display: none;
}


@media screen and (max-width: 1200px) {
  .nav-bar { 
    background: transparent;
    position: initial;
    height: auto;
    min-height: auto;

     ul, nav {
      display: none;
     }

     nav {
      width: 100%;
      top: 0;
      left: 0;
      background: #BDCDD6;
      height: 100%;
      margin: 0;
      z-index: 2;
      position: fixed;
      padding-top: 50px;

      &.mobile-show {
        display: block;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      a:after {
        opacity: 1;
        position: initial;
        width: auto;
        margin-left: 10PX;
      }

      a svg {
        opacity: 1 !important;
      }
     }

     .hamburger-icon,
     .close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        display: block;
     }
  }
}